<script lang="ts" setup>
const { $api } = useNuxtApp()

const props = defineProps<{
  visible: boolean
}>()

const emit = defineEmits<{
  'update:visible': [payload: boolean]
  success: []
}>()

const isSubmitting = ref(false)
const message = ref('')
const error = ref('')

const form = ref({
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  //   profile_image: '',
  //   gender: '',
  //   language: '',
  //   source: '',
  //device_token: fcm_token
})

const onClose = () => {
  emit('update:visible', false)
}

// const getProfile = async () => {
//   try {
//     isSubmitting.value = true
//     const res = await $api('/v2/profile')

//     if (res.profile_found) {
//         res.user_data
//     }
//   } catch (e) {
//     console.error(e.response.data)
//   } finally {
//   }
// }

const onSubmit = async () => {
  try {
    isSubmitting.value = true
    const res = await $api('/profile', {
      method: 'PUT',
      body: {
        ...form.value,
      },
    })

    if (res.profile_updated) {
      message.value = res.message
      onClose()

      emit('success')
    } else {
      error.value = res.message
    }
  } catch (e) {
    console.log(e)
    error.value = e.data.message
  } finally {
    isSubmitting.value = false
  }
}
</script>

<template>
  <BModal
    :model-value="visible"
    @update:model-value="(v) => emit('update:visible', v)"
    size="lg"
    hide-header
    hide-footer
    body-class="p-0"
    content-class="rounded-5 overflow-hidden"
  >
    <template #default>
      <div class="row g-0">
        <div class="col-lg-7">
          <div class="d-flex align-items-center flex-column justify-content-center p-5">
            <h1 class="text-center">يهلا، مرحبًا بعودتك!</h1>
            <p class="mb-4 text-center">دعنا نستكشف ما وراء العالم من خلال خدمات كار ترست</p>

            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="col-12 col-md-6">
                  <AppInput
                    v-model="form.first_name"
                    autofocus
                    placeholder="أدخل الاسم الأول"
                    type="text"
                    required
                    name="first_name"
                    label="اسم العائلة"
                  />
                </div>
                <div class="col-12 col-md-6">
                  <AppInput
                    v-model="form.last_name"
                    placeholder="أدخل الاسم الأخير"
                    type="text"
                    required
                    name="last_name"
                    label="الاسم الأول"
                  />
                </div>
                <div class="col-12">
                  <AppInput
                    v-model="form.email"
                    placeholder="أدخل عنوان البريد الالكتروني"
                    type="email"
                    required
                    name="email"
                    label="البريد الإلكتروني"
                  />
                </div>
                <div class="col-12">
                  <AppPhoneInput v-model="form.phone" placeholder="552759760" class="mb-2">
                    <template #label>
                      رقم الجوال
                      <span class="text-danger"> (غير قابل للتعديل)</span>
                    </template>
                  </AppPhoneInput>
                </div>
                <div class="col-12">
                  <BButton
                    variant="primary"
                    block
                    class="w-100"
                    size="lg"
                    type="submit"
                    :loading="isSubmitting"
                    loading-text=""
                  >
                    انشاء الحساب
                  </BButton>

                  <p v-if="error" class="mt-3 txt-danger">{{ error }}</p>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="d-none d-lg-block col-lg-5">
          <img src="assets/images/modal-car.png" class="w-100" alt="" />
        </div>
      </div>
    </template>
  </BModal>
</template>

<style lang="css" scoped></style>
