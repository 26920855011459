<script lang="ts" setup>
import { useUserStore, useUserProfileStore } from '@/store/userStore'
const store = useUserStore()
const storeUserProfile = useUserProfileStore()

const { $api } = useNuxtApp()

const props = defineProps<{
  visible: boolean
  phone: string
}>()

const emit = defineEmits<{
  'update:visible': [payload: boolean]
  success: [payload: void]
}>()

const otp = ref('')
const message = ref('')
const error = ref('')
const timeToOtp = ref(60)

const isSubmitting = ref(false)
const isOtpPending = ref(false)
const isErrorPending = ref(false)

const otpInterval = ref<any>(null)

const onClose = () => {
  otpInterval.value = null
  timeToOtp.value = 60
  otp.value = ''
  error.value = ''
  emit('update:visible', false)
}

const numberFormatter = new Intl.NumberFormat('ar-Sa')

const onRequestOTP = async () => {
  try {
    if (isSubmitting.value || timeToOtp.value > 0) return

    isOtpPending.value = true
    otp.value = ''
    const res = await $api('/request-otp', {
      method: 'POST',
      body: {
        phone: props.phone,
      },
    })

    startOtpInterval()
  } catch (e) {
    isErrorPending.value = e.data.message
  } finally {
    isOtpPending.value = false
  }
}

const onSubmit = async () => {
  try {
    isSubmitting.value = true
    let phone = props.phone // Get the phone number from props
    if (phone && phone.charAt(0) !== '0' && phone.charAt(0) === '5') {
      phone = '0' + phone // Add '0' at the beginning if it starts with '5'
    }
    const res = await $api('/verify-otp', {
      method: 'POST',
      body: {
        phone: phone,
        otp: otp.value,
      },
    })

    if (res.verified) {
      message.value = res.message
      onClose()

      store.setUserToLocalStorage(res)

      // check profile info
      const userProfileRes = await getUserProfile()

      if (
        userProfileRes?.user_info?.email &&
        userProfileRes?.user_info?.first_name &&
        userProfileRes?.user_info.last_name
      ) {
        storeUserProfile.setUserProfileToLocalStorage(userProfileRes.user_info)
        emit('success', res)
      } else {
        emit('openProfileModal')
      }
    } else {
      error.value = res.message
    }
  } catch (e) {
    console.log(e)
    error.value = e.data.message
  } finally {
    isSubmitting.value = false
  }
}

const getUserProfile = async () => {
  return await $api('/profile', {
    method: 'GET',
  })
}

const startOtpInterval = () => {
  timeToOtp.value = 60
  otpInterval.value = setInterval(() => {
    if (timeToOtp.value === 1) {
      timeToOtp.value = 0
      clearInterval(otpInterval.value)
      clearInterval(otpInterval.value)
      return
    }
    timeToOtp.value -= 1
  }, 1000)
}

watch(
  () => props.visible,
  (v) => {
    v && startOtpInterval()
  },
  {
    immediate: true,
  }
)
watch(otp, () => {
  error.value = ''
  message.value = ''
})
</script>

<template>
  <BModal
    :model-value="visible"
    @update:model-value="(v) => emit('update:visible', v)"
    size="lg"
    hide-header
    hide-footer
    body-class="p-0"
    content-class="rounded-5 overflow-hidden"
  >
    <template #default>
      <div class="row">
        <div class="col-lg-7">
          <div class="row justify-content-center h-100">
            <div class="col-lg-11">
              <div class="h-100 d-flex align-items-center flex-column justify-content-center p-5">
                <div>
                  <div class="fs-16 fw-400 textPrimary text-center mb-3">
                    دعنا نستكشف ما وراء العالم من خلال خدمات كار ترست
                  </div>
                  <div class="fs-32 fw-700 text-center mb-3">أدخل رمز التحقق</div>
                  <div class="fs-16 fw-400 textPrimary text-center mb-2">ادخل رمز التحقق المرسل الى رقم جوال</div>
                  <div class="fs-16 fw-600 mb-3 textPrimary text-center">{{ phone }}</div>

                  <AppOtpInput v-model="otp" />

                  <p v-if="error" class="m-0 text-danger text-center">{{ error }}</p>
                  <p v-else="message" class="m-0 text-success">{{ message }}</p>

                  <div class="fs-14 fw-400 mb-3 text-center" v-if="!(timeToOtp > 0)">
                    لم تحصل على رمز؟
                    <span class="textPrimary" @click="onRequestOTP">
                      <BSpinner v-if="isOtpPending" small />
                      <span v-else role="button"> انقر لإعادة الإرسال </span>
                      <BSpinner v-if="isOtpPending" small />
                      <span v-else role="button"> انقر لإعادة الإرسال </span>
                    </span>
                  </div>

                  <div class="text-center text-danger my-3" v-if="timeToOtp > 0">
                    الوقت لإعادة المحاولة بعد {{ numberFormatter.format(timeToOtp) }} ثواني
                  </div>

                  <div class="d-flex align-items-center gap-2">
                    <BButton
                      variant="primary"
                      class="w-50"
                      size="lg"
                      @click="onSubmit"
                      :disabled="isOtpPending"
                      :loading="isSubmitting"
                      loading-text=""
                    >
                      تحقق
                    </BButton>
                    <BButton
                      variant="outline-primary"
                      class="w-50"
                      size="lg"
                      @click="onClose"
                      :disabled="isSubmitting || isOtpPending"
                    >
                      <div class="d-flex gap-2 align-items-center justify-content-center">
                        <span> إلغاء </span>
                      </div>
                    </BButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-none d-lg-block col-lg-5">
          <img src="assets/images/modal-car.png" class="w-100" alt="" />
        </div>
      </div>
    </template>
  </BModal>
</template>

<style lang="css" scoped></style>
